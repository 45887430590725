window.VWFS = window.VWFS || {};
VWFS.DataLayer = function () {
    this.init = function () {
        console.debug("INFO datalayer.js loaded");

        var language = getContentIfNotNull('datalayer-language');
        var countryCode = getContentIfNotNull('datalayer-countryCode');

        var version = getContentIfNotNull('datalayer-version');
        var releaseDate = getContentIfNotNull('datalayer-releaseDate');

        var landingPageCorrespondingJourney = getContentIfNotNull('datalayer-landingPageCorrespondingJourney');
        var landingPageVariant = getContentIfNotNull('datalayer-landingPageVariant');
        var design = getContentIfNotNull('datalayer-design');
        var customBrand = "";
        if (getContentIfNotNull('datalayer-customBrand') != "") {
            customBrand = getContentIfNotNull('datalayer-customBrand');
        } else {
            customBrand = design;
        }

        var documentPathName = document.location.pathname;
        var documentPageName = "";
        var documentPageNameWithExtension = documentPathName.substring(documentPathName.lastIndexOf('/') + 1);
        if (documentPageNameWithExtension === "index.html" || documentPageNameWithExtension === "") {
            documentPathName = "home";
        } else {
            documentPageName = documentPathName.substring(documentPathName.lastIndexOf('/') + 1, documentPathName.lastIndexOf('.'));
        }

        var subCategoriesString = getContentIfNotNull('datalayer-siteSecondaryCategories');
        var subCategoriesStringArray = subCategoriesString.split(",");
        var category = {};
        category["primaryCategory"] = getContentIfNotNull('datalayer-sitePrimaryCategory');

        for (var i = 0; i < subCategoriesStringArray.length; i++) {
            if (subCategoriesStringArray[i] !== "")
                category["subCategory" + (i + 1)] = subCategoriesStringArray[i];
        }

        window.tracking = {
            page: {
                pageName: documentPageName,
                path: documentPathName,
                pageTitle: document.title,
                fullURL: document.location.href,
                referringURL: document.referrer,
                platform: "nbw",
                country: countryCode,
                language: language,
                brand: customBrand,
                landingPageCorrespondingJourney: landingPageCorrespondingJourney,
                landingPageVariant: landingPageVariant
            },
            core: {
                stagingEnvironment: "",
                dataLayerVersion: "",
                pageInfo: {
                    pageName: "",
                    intendedCustomerDeviceType: "",
                    version: version,
                    releaseDate: releaseDate,
                    language: language,
                    market: countryCode,
                    publisher: "DU"
                },
                category: {
                    primaryCategory: "",
                    secondaryCategory: "",
                    productVariants: [{
                        name: ""
                    }],
                    siteType: ""
                },
                attributes: {
                    journeyType: "",
                    viewChange: "",
                    brand: ""
                }
            },
            product: [{
                category: "",
                name: "",
                productVariants: [],
                productAddons: [],
                attributes: {
                    typeOfSale: ""
                },
                vehicleModel: [{
                    manufacturer: "",
                    name: "",
                    modelVariation: "",
                    year: "",
                    modelLine: "",
                    descriptionLong: "",
                    salesID: "",
                    bodyType: "",
                    category: "",
                    certifiedPreOwned: "",
                    currentMileage: "",
                    currentMileageUnit: "",
                    initialRegistrationDate: "",
                    currency: "",
                    endPrice_localCurrency: "",
                    colorExterior: "",
                    condition: "",
                    carClassificationCode: "",
                    engine: {
                        fuelType: "",
                        emission: "",
                        transmission: ""
                    }
                }]
            }],
            dealerData: {
                companyId: "",
                companyName: "",
                regionId: "",
                KVPS: "",
                address: {
                    street: "",
                    zipCode: "",
                    city: "",
                    state: ""
                }
            },
            design: {
                browserResolutionBreakpoint: ""
            },
            customerData: {
                loginStatus: "",
                loggedInUserGroup: ""
            },
            filter: [],
            search: {
                results: ""
            },
            event: [{
                eventInfo: {
                    eventType: "",
                    eventAction: "",
                    linkInformation: ""
                }
            }]
        };
    }

    function getContentIfNotNull(metaName) {
        if (document.querySelectorAll("meta[name='" + metaName + "']") && document.querySelectorAll("meta[name='" + metaName + "']")[0]) {
            var attribute = document.querySelectorAll("meta[name='" + metaName + "']")[0].getAttribute('content');
            return attribute !== null ? attribute : "";
        }
        return "";
    }
}
new VWFS.DataLayer().init();
